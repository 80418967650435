body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background: #f7f7f7;
}

header {
  border-bottom: 1px solid #eee;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.17);
  padding: 9px;
}
.alert {
  border-radius: 8px;
  color: #417505;
  padding: 5px 35px;
  max-width: 720px;
  margin: 50px auto;
}
.bg-confirm {
  background: #ddf3c7;
  border: 1px solid #dbfabc;
}
.card {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  max-width: 377px;
  margin: 52px auto;
  padding: 46px 33px;
  text-align: center;
}

.card h2 {
  font-size: 24px;
  font-weight: 600;
  color: #515151;
  max-width: 450px;
  margin: 20px auto;
}
.card .box-info {
  margin: 15px;
}
.card .box-info .email {
  display: block;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #000;
}
.card label {
  font-size: 18px;
  font-weight: 600;
  color: #4a4a4a;
  text-align: left;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.card span {
  font-size: 20px;
  color: #9b9b9b;
  letter-spacing: -0px;
  text-align: left;
  font-weight: 600;
}
.card form {
  max-width: 350px;
  margin: 0 auto;
}
.card input {
  background: #ffffff;
  border: 2px solid #dddddd;
  border-radius: 4px;
  width: 326px;
  height: 45px;
  padding: 0 10px;
}

.card form button {
  margin-top: 10px;
  height: 40px;
}
.success-msg {
  margin-top: 54px;
}
.success-msg > .success-msg__symbol {
  height: 61px;
  width: 61px;
  border-radius: 50%;
  background: #1abc9c;
  display: flex;
  margin: auto;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.04);
}
.success-msg > .success-msg__symbol > .icon-light_check {
  color: #ffffff;
  font-size: 24px;
  margin: 21px 13px;
}

.card.notfound {
  padding: 34px 22px;
  max-width: 330px;
  margin-top: 120px;
}

.notfound > p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 44px;
  line-height: 21px;
  color: #515151;
}

.notfound > .btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 10px;
}
.notfound > .btn-group > .btn-login {
  padding: 7px 33px;
  background: #FFFFFF;
  border: 1px solid #1ABC9C;
  box-sizing: border-box;
  border-radius: 16px;
  color: #1ABC9C;
  font-size: 14px;
  cursor: pointer;
}
.notfound > .btn-group > .btn-register {
  padding: 7px 33px;
  background: #7D60F5;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.card.login {
  padding: 66px 60px;
  max-width: 377px;
}

.login > h2 {
  color: #000000;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0px;
}
.login > p {
  color: #707070;
  font-weight: normal;
  font-size: 16px;
  margin-top: 0px;
}
.login .forgoted {
  text-align: right;
  font-size: 17px;
  color: #88ADFF;
  margin-top: 5px;
  width: 100%;
}
.login .donthave {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 21px;
}
.card.register .form-control,
.card.login .form-control {
  position: relative;
}
.card.register .form-control .exclamation-error,
.card.login .form-control .exclamation-error {
  position: absolute;
  top: 33px;
  right: 10px;
}
.login > .btn-group > .btn-login,
.button-wrapper > .btn-login {
  background: #7D60F5;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  width: 180px;
  cursor: pointer;
}
.login > .btn-group > .btn-login:disabled ,
.button-wrapper > .btn-login:disabled {
  background: #9d87f7;
  box-shadow: none;
}

.card.register {
  padding: 66px 60px;
  max-width: 377px;
}

.register > h2 {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}
.register > .email {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.register > .site {
  color: #707070;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0px;
}
.register > .details {
  color: #515151;
  font-weight: 600;
  font-size: 16px;
  margin-top: 32px;
  text-align: left;
}
.register > .btn-group > .btn-login {
  padding: 11px 33px;
  background: #7D60F5;
  border-radius: 20px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  margin-top: 31px;
  width: 142px;
  cursor: pointer;
}

.loading {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading > img {
  border-radius: 50%;
  animation: rotate-center 2s linear infinite both;
}
 @-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



a {
  color: #88ADFF;
  cursor: pointer;
}
.form-control {
  padding: 0px;
} 
.form-control > label {
  font-size: 14px;
  font-weight: 700;
  color: #515151;
  margin-bottom: 4px;
  margin-top: 8px;
  text-align: left;
}
.form-control > input {
  background: #FFFFFF;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 2px 11px;
  height: 30px;
  width: 94%;
}
.form-control .help-block {
  font-size: 12px;
  color: #515151;
  margin-bottom: 4px;
  margin-top: 1px;
  text-align: left;
}
.form-control > input::placeholder {
  color: #D8D8D8;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.form-control > input:focus {
  border-radius: 10px;
  border-color: #7D60F5;
  border-radius: 4px;
  outline: none;
}


/*///////////////////////////////////////////////////////////////////////*/



.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}
.button-wrapper.loading {
  height: auto;
}
.button-wrapper.loading > .loading-ring {
  display: inline-block;
}
.button-wrapper > .loading-ring {
  display: none;
}
.btn-cliengo {
  border: 0;
  border-radius: 24px;
  box-shadow: 0 3px 6px #00000029;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  text-align: center;
  width: 100%;
}
.btn-cliengo.send {
  color: white;
  transition: all 0.5s;
  
}
.btn-cliengo.send > .loading-ring {
  display: none;
}
.btn-cliengo.send  > .loading-ring:disabled {
  background: #969696;
  color: white;
}

.button-wrapper.loading > .btn-cliengo.send {
  background: #7d60f5;
  font-size: 0;
  transition: all 0.8s;
  width: 40px;
}

.button-wrapper.success > .btn-cliengo.send {
  background: #1abc9c;
  font-size: 0;
  transition: all 0.8s;
  width: 40px;
}

.button-wrapper.failure > .btn-cliengo.send {
  background: #b1346e;
  font-size: 0;
  transition: all 0.8s;
  width: 40px;
}

.button-wrapper.success > .loading-ring,
.button-wrapper.failure > .loading-ring {
  transform: scale(0);
  transition: all 0.5s;
}

.button-wrapper.success::before,
.button-wrapper.failure::before {
  animation: appear 0.3s linear;
  color: white;
  font-family: 'icomoon' !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  margin-left: 2px;
  position: absolute;
  speak: never;
  top: 21px;
}

.button-wrapper.success::before {
  content: '\e901';
}

.button-wrapper.failure::before {
  content: '\e904';
}



.loading-ring {
  display: inline-block;
  height: 40px;
  position: absolute;
  top: 9px;
  width: 40px;
}

.loading-ring div {
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 4px solid white;
  border-color: white transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 30px;
  margin: 5px;
  position: absolute;
  width: 30px;
}

.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}


@keyframes loading-ring {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes appear {
  from {
    transform: scale(0);
  }
  
  to {
    transform: scale(1);
  }
}