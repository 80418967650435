.form__container__password_container{
    position: relative;
    width: 100%;
}
  .form__container__password_container input {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgb(0 0 0 / 4%);
    border-radius: 4px;
    padding: 2px 11px;
    height: 30px;
    width: 94%;
}
.form__container__field__toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
}

.form__container__field.error {
    border: 1px solid #B1346E;
    outline: none;
}
.form__div__message_error {
    color: #B1346E;
    text-align: left;
}

.form__div__box_error {
    border: 1.5px solid #B1346E;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
}
.form__div__box_error i {
    color: #B1346E;
    margin-right: 15px;
    font-size: 24px;
}
.form__div__box_error p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #B1346E;
    text-align: left;
}

.div__message_error{display: none;}